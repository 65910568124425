import React from 'react'
import * as moment from "moment"

export default function StatusIcon(props) {

  const statusIconMap = {
    'OPEN': {
      classes: 'fas fa-eye',
      style: {},
      title: moment(Math.floor(props.value)).fromNow()
    },
    'NOT_OPEN': {
      classes: 'far fa-eye-slash text-muted',
      style: { opacity: 0.4 },
      title: 'Not seen'
    },
    'REPLIED': {
      classes: 'fas fa-reply text-success',
      style: {},
      title: moment(Math.floor(props.repliedAt)).fromNow()
    },
    'NOT_REPLIED': {
      classes: 'fas fa-reply text-muted',
      style: { opacity: 0.4 },
      title: 'No reply'
    },
  }

  let tag = ''
  switch (props.variant) {
    case 'open':
      tag = props.value ? 'OPEN' : 'NOT_OPEN'
      break;

    case 'reply':
      tag = props.value ? 'REPLIED' : 'NOT_REPLIED'
      break;

    default:
      break;
  }

  // const classes = props.className ? statusIconMap[tag].classes + ' ' + props.className : statusIconMap[tag].classes
  const classes = `${statusIconMap[tag].classes} ${props.className || ''}`

  return (
    <i
      className={classes}
      style={{
        ...statusIconMap[tag].style
      }}
      title={statusIconMap[tag].title}
    >
    </i>
  )
}