import Home from "./Routes/Home/Home"
import Login from "./Routes/Login/Login"
import Dropzone from "./Routes/Dropzone"

import Drops from './Routes/Dashboard/Drops/Drops'
import Archive from './Routes/Dashboard/Archive/Archive'
import Trash from './Routes/Dashboard/Trash/Trash.jsx'
import Dashboard from "./Routes/Dashboard/Dashboard"
import AccountSettings from "./Routes/AccountSettings/AccountSettings"
import PrivacyPolicy from "./Routes/PrivacyPolicy/PrivacyPolicy"
import TermsOfUse from "./Routes/TermsOfUse/TermsOfUse"


export const routes = {
  home: {
    path: '/',
    component: Home,
    exact: true,
    private: false
  },
  login: {
    path: '/login',
    component: Login,
    exact: true,
    private: false
  },
  register: {
    path: '/register',
    component: Login,
    exact: true,
    private: false
  },
  s: {
    path: '/s',
    component: Dropzone,
    exact: false,
    private: false
  },
  dashboard: {
    path: '/dashboard',
    component: Dashboard,
    exact: false,
    private: true
  },
  accountSettings: {
    path: '/account-settings',
    component: AccountSettings,
    exact: false,
    private: true
  },
  privacyPolicy: {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
    private: true
  },
  termsOfUse: {
    path: '/terms-of-use',
    component: TermsOfUse,
    exact: true,
    private: true
  },
}

export const dashboard = {
  basePath: '/dashboard/',
  routes: {
    drops: {
      name: 'Active Drops',
      path: 'drops',
      component: Drops,
      exact: true,
      dataStatusCode: 2
    },
    archive: {
      name: 'Archived',
      path: 'archive',
      component: Archive,
      exact: true,
      dataStatusCode: 1
    },
    trash: {
      name: 'Trash',
      path: 'trash',
      component: Trash,
      exact: true,
      dataStatusCode: 0
    },
  }
}

export const userHomePath = routes.dashboard.path