import React, { useState } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'
import { Nav, NavItem, Navbar, NavbarToggler, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from 'reactstrap'
import logo from '../../picdrop-logo-white.svg'
import ProfilePic from '../ProfilePic/ProfilePic'
import { routes } from '../../routes'

export default function MainNavBar(props) {
  const { displayName, email } = useSelector((state) => state.firebase.auth)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const toggle = () => { setIsOpen(!isOpen) }
  const close = () => { setIsOpen(false) }

  const history = useHistory()
  const firebase = useFirebase()

  const logout = () => {
    firebase
      .logout()
      .then(() => {
        dispatch({ type: actionTypes.CLEAR_DATA })
        history.push(routes.home.path)
      })
  }

  return (
    <Navbar expand="md" fixed="top" className="navbar-dark bg-dark ">
      <Container>
        <Link
          onClick={close}
          to={routes.home.path}
          className="navbar-brand">
          <img src={logo} alt='' style={{ height: 40 }}
          />
        </Link>

        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>

            <NavItem>
              <NavLink
                to={routes.dashboard.path}
                className="nav-link"
                activeClassName="active"
              >
                Dashboard
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret style={{ outline: 'none' }}>
                <ProfilePic />
              </DropdownToggle>
              <DropdownMenu right>
                <div className='mb-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ProfilePic size={64} style={{ marginTop: '15px', marginBottom: '10px' }} />
                  {displayName ? displayName : email}
                </div>
                <DropdownItem divider />

                <DropdownItem tag={Link} to={routes.accountSettings.path} >
                  <div>
                    <small className="text-muted"><em>available soon</em></small>
                  </div>
                  <i className="fas fa-user-cog mr-2"></i>
                  Account settings
                </DropdownItem>

                <DropdownItem divider />
                
                <DropdownItem tag={Link} to={routes.privacyPolicy.path} >
                  <i className="fas fa-user-shield mr-2"></i>
                  Privacy Policy
                </DropdownItem>
                
                <DropdownItem tag={Link} to={routes.termsOfUse.path} >
                  <i className="fas fa-list-alt mr-2"></i>
                  Terms of use
                </DropdownItem>
                
                <DropdownItem divider />
                
                <DropdownItem onClick={() => { logout() }}>
                  <i className="fas fa-power-off mr-2"></i>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}