import React from 'react'
import cssModule from '../Dropzone.module.css'

export default function Thumbs({files, modal}) {

  const ImageTag = props => {
    return (
      <img src={props.src} alt={`Thumbnail ${props.index + 1}`} className={cssModule.dropzone_thumb_inner_img} />
    )
  }

  return (
    files.map((file, key) => (
        <div className={cssModule.dropzone_thumb} key={key}>
          <div className={cssModule.dropzone_thumb_inner}>
            <div className={cssModule.dropzone_thumb_inner_div}>
              {modal ?
                <a href={file.preview}>
                  <ImageTag src={file.preview} index={key} />
                </a>
                :
                <ImageTag src={file.preview} index={key} />
              }
            </div>
          </div>
        </div>
      ))
  )
}
