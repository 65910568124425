import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const PrivacyPolicy = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h1" className="text-center mb-4">
                Privacy Policy
              </CardTitle>

              <p><strong>Effective Date:</strong> January 1st, 2025</p>
              <p><strong>Last Updated:</strong> January 28th, 2025</p>

              <p>
                Welcome to <strong>PicDrop</strong> (<a href="https://picdrop.space" target="_blank" rel="noopener noreferrer">https://picdrop.space</a>).
                Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your data while
                complying with the <strong>General Data Protection Regulation (GDPR).</strong>
              </p>

              <h2 className="mt-4">1. Data We Collect</h2>
              <p>We collect the following types of information:</p>
              <ul>
                <li><strong>Account Information:</strong> Your email and other details when signing up.</li>
                <li><strong>Drop Data:</strong> Titles, recipient email addresses, and filenames (not actual image files).</li>
                <li><strong>Log Data:</strong> IP addresses, browser types, and website interactions for security and analytics.</li>
                <li><strong>Cookies & Tracking:</strong> We use cookies for session management and analytics. You can disable cookies in your browser settings.</li>
              </ul>

              <h2 className="mt-4">2. How We Use Your Data</h2>
              <ul>
                <li>To provide and maintain PicDrop services.</li>
                <li>To send transactional emails related to drop requests.</li>
                <li>To improve security and prevent fraud.</li>
                <li>To comply with legal obligations.</li>
              </ul>

              <h2 className="mt-4">3. GDPR Compliance & Your Rights</h2>
              <p>Under GDPR, users in the European Union have the following rights:</p>
              <ul>
                <li><strong>Right to Access:</strong> Request a copy of the data we hold about you.</li>
                <li><strong>Right to Rectification:</strong> Request corrections to inaccurate or incomplete data.</li>
                <li><strong>Right to Erasure:</strong> Request deletion of your personal data when legally applicable.</li>
                <li><strong>Right to Restrict Processing:</strong> Request that we limit how we use your data.</li>
                <li><strong>Right to Data Portability:</strong> Receive your personal data in a structured format.</li>
                <li><strong>Right to Object:</strong> Object to data processing based on legitimate interests.</li>
              </ul>
              <p>To exercise your rights, contact us at <strong>gdpr@picdrop.space</strong></p>

              <h2 className="mt-4">4. Data Sharing & Storage</h2>
              <p>We do not sell your personal data. We may share data with:</p>
              <ul>
                <li>Third-party service providers (e.g., email services).</li>
                <li>Authorities if required by law.</li>
              </ul>
              <p>Your data is stored securely and retained only as long as necessary for our services.</p>

              <h2 className="mt-4">5. Data Security</h2>
              <p>We implement industry-standard security measures to protect your data. However, no system is 100% secure.</p>

              <h2 className="mt-4">6. Cookies & Tracking</h2>
              <p>PicDrop uses cookies for authentication and analytics. You can manage cookie settings in your browser.</p>

              <h2 className="mt-4">7. Changes to This Policy</h2>
              <p>We may update this Privacy Policy. The latest version will always be available on this page.</p>

              <h2 className="mt-4">8. Contact Us</h2>
              <p>If you have any questions, contact us at <strong>support@picdrop.space</strong></p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
