import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { isLoaded, isEmpty } from "react-redux-firebase"
import { useSelector } from "react-redux"
import MainNavBar from './MainNavBar/MainNavBar'
import { dashboard } from '../routes'


export default function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)
  const { routes, basePath } = dashboard

  // console.log(auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          <>
            <MainNavBar routes={routes} basePath={basePath} />
            {children}
          </>
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
