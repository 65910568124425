import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const TermsOfUse = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h1" className="text-center mb-4">
                Terms of Use
              </CardTitle>

              <p><strong>Last Updated:</strong> January 31st, 2025</p>

              <p>
                Welcome to <strong>PicDrop</strong> (
                <a href="https://picdrop.space" target="_blank" rel="noopener noreferrer">
                  https://picdrop.space
                </a>).
                These Terms of Use ("Terms") govern your access to and use of the PicDrop website and related services (collectively, the "Service"). 
                By using the Service, you agree to be bound by these Terms. If you do not agree, please do not use the Service.
              </p>

              <h2 className="mt-4">1. Acceptance of Terms</h2>
              <p>
                By accessing or using PicDrop, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy.
              </p>

              <h2 className="mt-4">2. Description of Service</h2>
              <p>
                PicDrop provides a platform for photographers to collect offline photo selections from their clients. 
                Users create "drops" to facilitate the selection process, and clients can submit their selections through a dedicated link.
              </p>

              <h2 className="mt-4">3. User Accounts</h2>
              <ul>
                <li>To use certain features, you must create an account.</li>
                <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                <li>You agree to provide accurate and complete information during registration and keep it updated.</li>
              </ul>

              <h2 className="mt-4">4. Use of the Service</h2>
              <ul>
                <li>You agree to use the Service only for its intended purposes and in compliance with applicable laws.</li>
                <li>You must not misuse the Service, including but not limited to uploading unlawful or offensive content, interfering with other users, or attempting to gain unauthorized access.</li>
              </ul>

              <h2 className="mt-4">5. Intellectual Property</h2>
              <ul>
                <li>All content and materials on PicDrop, including but not limited to trademarks, logos, and software, are owned or licensed by us.</li>
                <li>Users retain ownership of their uploaded content but grant PicDrop a limited license to store and display the content as necessary to provide the Service.</li>
              </ul>

              <h2 className="mt-4">6. Termination</h2>
              <p>We reserve the right to suspend or terminate accounts that violate these Terms, at our sole discretion.</p>

              <h2 className="mt-4">7. Limitation of Liability</h2>
              <ul>
                <li>The Service is provided "as is" without warranties of any kind.</li>
                <li>We are not responsible for any losses, damages, or issues arising from your use of the Service.</li>
              </ul>

              <h2 className="mt-4">8. Modifications to the Terms</h2>
              <p>We may update these Terms from time to time. Continued use of the Service after modifications constitutes acceptance of the revised Terms.</p>

              <h2 className="mt-4">9. Contact Information</h2>
              <p>
                For any questions regarding these Terms, please contact us at{" "}
                <strong>support@picdrop.space</strong>.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfUse;
