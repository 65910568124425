import React from 'react'
import { useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import { Switch, Route, Redirect, NavLink as Link } from 'react-router-dom'
import { dashboard } from '../../routes'
import { Nav, NavItem, Badge, NavLink } from 'reactstrap'
import DropItem from './Components/DropItem/DropItem'

export default function Dashboard(params) {
  const { uid } = useSelector((state) => state.firebase.auth)
  const { routes, basePath } = dashboard

  const drops = useSelector((state) => state.firestore.data.drops)  

  useFirestoreConnect({
    collection: `users/${uid}/drops`,
    // where: ['status', '>=', 0],
    orderBy: ['created_at', 'desc'],
    storeAs: "drops",
  })    

  const filterDrops = (status) => {
    if (drops) {
      const filteredDrops = Object.values(drops).filter(drop => drop && drop.status === status).map((drop, key) => (
        <DropItem
          key={key}
          {...drop}
        />
      ))
      return filteredDrops
    } else {
      return false
    }
  }

  return (
    <>
      <Nav pills className='mt-5 mb-5'>

        {Object.keys(routes).map((routeKey, key) => {
          return (
            <NavItem key={key}>
              <NavLink
                tag={Link}
                activeClassName="active"
                to={basePath + routes[routeKey].path}
                disabled={filterDrops(routes[routeKey].dataStatusCode).length === 0 && routes[routeKey].path !== 'drops'}
              >
                {routes[routeKey].name}
                {filterDrops(routes[routeKey].dataStatusCode).length > 0 &&
                  <Badge color="secondary" className='ml-1' pill>{filterDrops(routes[routeKey].dataStatusCode).length}</Badge>
                }
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>

      <Switch>
        {Object.keys(routes).map((routeKey, key) => {
          const RouteComponent = routes[routeKey].component
          return (
            <Route exact
              path={basePath + routes[routeKey].path}
              key={key}
              component={() => <RouteComponent drops={filterDrops(routes[routeKey].dataStatusCode)} dataStatusCode={routes[routeKey].dataStatusCode} />}
            >
            </Route>
          )
        })}

        <Route render={() => <Redirect to="/dashboard/drops" />} />
      </Switch>
    </>
  )


}